<template>
  <div class="news">
    <div class="g-layout">
      <div class="g-layout-r" style="margin-left: 0">
        <div class="m-card m-main" v-if="newMsg">
          <div class="m-news-title">
            <h3>{{ newMsg.title }}</h3>
            <p class="time">
              <span>Date：{{ newMsg.updateAt.slice(0, 11) }}</span><span>View：{{ newMsg.viewCount }}</span>
            </p>
            <div class="m-news-content">
              <p style="font-size: 14px;margin: 10px 0;" v-if="newMsg.content">Outline：{{ newMsg.content }}</p>
              <div class="comm_text" v-if="newMsg.introduction">
                <div v-for="(item, index) in newMsg.introduction" :key="index">
                  <!-- 文本 -->
                  <p class="int-text" v-if="item.text" :style="{'font-size': item.text.fontSize,color: item.text.color,'text-align': item.text.position}" v-html="$service.replaceSpeace(item.text.content)"></p>
                  <!-- 图片 -->
                  <div class="int-img" v-if="item.picture && item.picture.type == 1" :style="{'padding-left': item.picture.padding + 'px','padding-right': item.picture.padding + 'px'}">
                    <div v-for="(itm, idx) in item.picture.list" :key="idx" :style="{ 'margin-bottom': item.picture.spacing + 'px' }">
                      <img :src="itm.url" style="width: 100%; display: block" />
                    </div>
                  </div>

                  <div style="width: 750px;" v-if="item.picture && item.picture.type == 2 && item.picture.list && item.picture.list.length">
                    <Carousel loop autoplay :radius-dot="true" :autoplay-speed="5000">
                      <CarouselItem v-for="(item, index) in item.picture.list" :key="'pic' + index">
                        <img :src="item.url" style="width: 100%;height: 100%;" />
                      </CarouselItem>
                    </Carousel>
                  </div>

                  <!-- 视频 -->
                  <div class="int-video" v-if="item.video && item.video.link" style="position:relative;">
                    <videoPlayer :url="item.video.link" :cover="item.video.cover" :time="item.video.time"></videoPlayer>
                  </div>
                </div>
              </div>
              <richContent v-if="newMsg && newMsg.content2" :info="newMsg.content2"></richContent>
            </div>
          </div>
        </div>
        <div class="no-msg" v-if="loading">{{ $t("lang.loading") }}</div>
        <div class="no-msg" v-if="!loading && !newMsg">{{ $t("lang.No_data") }}</div>
      </div>
      <div class="g-layout-l" style="margin-left: 1.66667%">
        <newsHot></newsHot>
        <!-- <newsTime></newsTime> -->
      </div>
    </div>
  </div>
</template>

<script>
import newsHot from "./news-hot";
import videoPlayer from "@components/videoPlayer/videoPlayer.vue";
// import newsTime from "./news-time";
import { mapActions, mapGetters } from "vuex";
import richContent from "@components/rich-content";

export default {
  name: "home",
  components: {
    newsHot,
    richContent,
    // newsTime,
    videoPlayer
  },
  data () {
    return {
      newMsg: "",
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      userId: "getUserId",
      exhibitionInfo: "getExhibitionInfo",
      exhibitionId: "getExhibitionId",
      user: "getUser",
    }),
    newId () {
      return this.$route.query.newId;
    },
  },
  watch: {
    newId () {
      this.init();
    },
  },
  created () {
    this.init();
    if (this.newId) {
      let opt = {
        exhibitionId: this.exhibitionId,
        target: "articles",
        targetId: this.newId,
      };
      if (this.userId) {
        opt.userId = this.userId;
      }
      this.addView(opt);
    }
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
      addView: "loginModule/addView",
    }),
    async setShare () {
      // console.log( this.newMsg,'neMus')
      let share = {
        url: location.href,
        title: this.newMsg.title + "-" + this.exhibitionInfo.name,
        desc: "",
        summary: this.exhibitionInfo.remark,
        pics: this.newMsg.covers && this.newMsg.covers.length ? this.newMsg.covers[0] : this.exhibitionInfo.logo,
      };
      this.$store.commit("setShare", share);
    },
    init () {
      this.getNewsDetails();
    },
    async getNewsDetails () {
      let query = `#graphql
        query($id: String!){
          articleQuery{
            get(id:$id){
              articleType
              author
              content
              content2
              covers
              createAt
              favoriteCount
              id
              introduction
              likeCount
              publishAt
              itemId
              shareCount
              subtitle
              title
              updateAt
              userCount
              viewCount
            }
          }
        }
      `;
      let opt = {
        query: query,
        variables: {
          id: this.newId,
        },
      };
      let data = await this.graphqlPost(opt);
      if (data.data.articleQuery.get) {
        let res = JSON.parse(JSON.stringify(data.data.articleQuery.get));
        this.newMsg = res;
        if (this.newMsg.covers.search(/^\[/) !== -1) {
          this.newMsg.covers = JSON.parse(this.newMsg.covers);
        } else {
          this.newMsg.covers = [this.newMsg.covers];
        }
        if (this.newMsg.introduction && this.newMsg.introduction.indexOf("[") !== -1) {
          this.newMsg.introduction = JSON.parse(this.newMsg.introduction);
        } else {
          this.newMsg.introduction = [];
        }
      }
      this.loading = false;
      document.title = this.newMsg ? this.exhibitionInfo.nameEn : "ExShow";
      this.setShare();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.news {
  width: 1200px;
  margin: 0 auto;
}
.home-l {
  margin-right: 20px;
  width: 880px;
  background: #fff;
  padding: 0 20px;
  .new-list {
    padding: 25px 0;
    border-bottom: 1px solid #f2eded;
    display: flex;
    align-items: center;
    cursor: pointer;
    &-l {
      width: 185px;
      height: 120px;
      background-color: #dcdcdc;
      border-radius: 5px;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }
    &-r {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 120px;
      width: 630px;
      h2 {
        font-size: 20px;
        font-weight: bold;
        line-height: 30px;
        color: #333;
      }
      p {
        font-size: 14px;
        line-height: 24px;
        color: #333;
        position: relative;
        top: -6px;
      }
      h2,
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      span {
        font-size: 12px;
        line-height: 24px;
        color: #666;
      }
    }
  }
  .new-list:last-child {
    border-bottom: none;
  }
  .new-list:hover {
    h2 {
      @include font_color(#0190fe);
    }
  }
}
.list-box {
  background: #fff;
  padding: 0 20px 20px 20px;
  width: 100%;
  .list-one {
    margin-top: 20px;
    img {
      width: 100%;
      height: 180px;
      display: block;
    }
    p {
      width: 60%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      line-height: 18px;
      color: #333;
      margin-top: 10px;
    }
    span {
      font-size: 12px;
      color: #666;
    }
  }
  .list-one:last-child {
    border: none;
  }
  .list-one:hover {
    p {
      @include font_color(#0190fe);
    }
  }
}
.m-main {
  padding: 40px 60px;
  .m-news {
    &-title {
      h3 {
        font-size: 30px;
        font-weight: bold;
        line-height: 40px;
        color: #333;
      }
      .time {
        span {
          font-size: 14px;
          color: #666;
          margin-right: 30px;
        }
        margin: 28px 0 15px;
      }
    }
    &-content {
      border-top: 1px solid #d9d9d9;
    }
  }
}

.comm_text {
  background: #fff;
  color: #333;
  padding: 35px 0;
  margin-bottom: 20px;
  > span {
    line-height: 1;
    padding: 0 0 20px 30;
    font-size: 32px;
    display: inline-block;
  }
  p {
    line-height: 1.6;
    font-size: 14px;
  }
  img {
    display: block;
  }
  .int-text {
    padding: 30px 0;
  }
}

.no-msg {
  font-size: 14px;
  color: #666;
  text-align: center;
  margin-top: 100px;
}
.load-more {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #666;
  padding: 20px 0;
  cursor: pointer;
}
.m-news-content {
  // padding: 50px 65px 65px 75px;

  p {
    color: #333;
    line-height: 24px;
    margin-bottom: 20px;
  }

  img {
    width: 700px;
    margin-bottom: 30px;
  }
  .int-video {
    width: 700px;
  }
}
</style>
